import React from "react"
import Layout from "../components/layout"

const Projects = ({location}) => {
  return (
    <Layout location={location} title="Projects">
      <div>Projects</div>
    </Layout>
  )
}

export default Projects
